<template>
  <div class="swiper">
    <el-carousel :interval="10000">
      <el-carousel-item v-for="item in imgList" :key="item.id">
        <img :src="item.img" alt="" />
        <h3 class="desc_word">
          {{ item.desc }}
          <br />
          <div class="detail_word" v-if="item.detail_desc != ''">
            {{ item.detail_desc }}
          </div>
        </h3>

        <div class="desc_btn" v-if="item.desc_btn != null">
          {{ item.desc_btn }}
          <i class="iconfont icon-youjiantou1 desc_icon"></i>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
export default {
  props: ["imgList"],
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<!-- style 主要引入于 antd轮播样式的穿透使用-->
<style scoped>
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
/deep/ .el-carousel__container {
  height: 530px !important;
}
</style>
<style  lang='less' scoped>
.swiper {
  position: relative;
  height: 530px;
  width: 100vw;
  img {
    width: 100% !important;
    height: 100%;
    object-fit: fill;
  }
  .desc_word {
    position: absolute;
    left: 0;
    top: 0;
    left: 50%;
    top: 40%;

    transform: translate(-50%, -50%);
    z-index: 10;
    font-size: 30px;
    font-weight: 500;
    animation: DescSwiper 2s ease-in-out forwards;
    color: #fff;
  }
  .desc_btn {
    display: flex;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    left: 50%;
    top: 75%;
    transform: translate(-50%, -50%);
    z-index: 10;
    padding: 15px;
    font-size: 24px;
    font-weight: 500;
    animation: DescSwiper 2s ease-in-out forwards;
    border: 2px solid #fff;
    color: #fff;
  }
  .desc_btn:hover .desc_icon {
    height: 40px;
    font-size: 20px;
    margin-left: 10px;
    margin-top: -5px;
  }
  .desc_btn:hover {
  }
  .desc_icon {
    height: 0;
    transition: all 0.3s ease;
    overflow: hidden;
    margin-right: -10px;
    font-size: 12px;
    line-height: 50px;
  }
  .detail_word {
    font-size: 20px;
  }
}
// 给轮播图上的文字 动态显示内容
@keyframes DescSwiper {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>